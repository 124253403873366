/*
 * @Author: chenxing
 * @Date: 2021-03-04 16:46:40
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-02 18:26:57
 */
/**
 * 1. 设置请求超时时间
 * 2. 添加请求拦截器，在每个请求的头部添加auth信息
 * 3. 添加响应拦截器，根据服务器返回状态进行相应的结果返回
 */
'use strict';
import axios from 'axios';
import router from '@/router';
import { message } from 'ant-design-vue';
import storage from 'store';

if (storage.get('login_invalid_warn_showing')) {
  storage.remove('login_invalid_warn_showing');
}

const defaultConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  // 请求超时时间
  timeout: 600 * 1000,
  // 跨域请求时是否需要凭证
  withCredentials: true, // Check cross-site Access-Control
  heards: {
    get: {
      // 设置默认请求头，当需要特殊请求头时，将其作为参数传入，即可覆盖此处的默认参数
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    post: {
      // 设置默认请求头，当需要特殊请求头时，将其作为参数传入，即可覆盖此处的默认参数(第三个参数即config)
      // 例如：
      //     services.post(`${base.lkBaseURL}/uploads/singleFileUpload`, file, {
      //       headers: { 'Content-Type': 'multipart/form-data' }
      //     });
      'Content-Type': 'application/json;charset=utf-8',
    },
  },
};

// 请求拦截器
const requestInterceptor = function(config) {
  return config;
};

const requestErrorHandler = function(error) {
  // Do something with request error
  error.data = {};
  error.data.msg = '服务器异常';
  return Promise.reject(error);
};

// 响应拦截器
const responseInterceptor = function(response) {
  if (response.status === 200) {
    if (response.data.code !== 0) {
      if (response.data.code === 401) {
        storage.remove('login');
        if (!storage.get('login_invalid_warn_showing')) {
          storage.set('login_invalid_warn_showing', 1);
          message.info(response.data.msg, () => {
            storage.remove('login_invalid_warn_showing');
          });
        }
        router.replace('/login');
        return;
      }
      if (response.data.msg) {
        message.error(response.data.msg, 0.5);
      }
    }
    return response.data;
  }
  response.data.code = -1;
  response.data.msg = '服务器错误';
  return Promise.reject(response.data);
};

const responseErrorHandler = function(error) {
  // 取消请求
  if (error.message === 'cancel request') {
    return Promise.reject(error.message);
  }
  if (error) {
    // 请求已发出，但不在2xx范围内
    /**
     * 请求失败后的错误统一处理，当然还有更多状态码判断，根据自己业务需求去扩展即可
     * @param status 请求失败的状态码
     * @param msg 错误信息
     */
    // 如果是登录接口，不需要跳转，直接提示信息
    if (error.response?.config.url == '/login') {
      return Promise.reject();
    }
    // 状态码判断
    switch (error.response?.status) {
      // 401: 未登录状态，跳转登录页
      // 403 token过期
      case 401:
      case 403:
        // 跳转登录页
        router.replace('/login');
        break;
      // 404请求地址不存在
      case 404:
        // 提示资源不存在
        message.error('请求失败，请联系系统管理员');
        break;
      case 500:
      case 503:
        // 提示服务器错误
        message.error('服务出错了，请稍后重新尝试');
        break;
      default:
        break;
    }
    return Promise.reject(error);
  } else {
    // 断网
    return Promise.reject(error);
  }
};

const _axios = axios.create(defaultConfig);
_axios.interceptors.request.use(
  config => requestInterceptor(config),
  error => requestErrorHandler(error)
);
_axios.interceptors.response.use(
  response => responseInterceptor(response),
  error => responseErrorHandler(error)
);
export default _axios;
export const CancelToken = axios.CancelToken;
export const lydata_prefix = '/lydata';
export const advert_prefix = '/advert';
export const host_prefix = '/monitor';
// 拼接get请求
export const getUrl = (url, params) => {
  let str = '';
  Object.keys(params).forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
};
