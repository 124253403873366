/*
 * @Author: chenxing
 * @Date: 2021-04-09 13:54:56
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-10 10:04:17
 */
/* @param { number; } number 输入数字.
 * @param { number; } decimalDigit 小数点后最多位数，默认为2
 * @return { string } 加上单位后的数字
 */
import * as echarts from 'echarts';

function addWan(integer, number, mutiple, decimalDigit) {
  var digit = getDigit(integer);
  if (digit > 3) {
    var remainder = digit % 8;
    if (remainder >= 5) {
      // ‘十万’、‘百万’、‘千万’显示为‘万’
      remainder = 4;
    }
    let result = Math.round(number / Math.pow(10, remainder + mutiple - decimalDigit)) / Math.pow(10, decimalDigit);
    return result % 1 == 0 ? result + '.00万' : result.toFixed(2) + '万';
  } else {
    return Math.round(number / Math.pow(10, mutiple - decimalDigit)) / Math.pow(10, decimalDigit);
  }
}

function getDigit(integer) {
  var digit = -1;
  while (integer >= 1) {
    digit++;
    integer = integer / 10;
  }
  return digit;
}

export function isNumber(val) {
  var regPos = /^[0-9]+.?[0-9]*/; //判断是否是数字。
  if (regPos.test(val)) {
    return true;
  } else {
    return false;
  }
}

export function addChineseUnit(number, decimalDigit) {
  if (number === 0) {
    number = '0';
  }
  decimalDigit = decimalDigit == null ? 2 : decimalDigit;
  var integer = Math.floor(number);
  var digit = getDigit(integer);
  // ['个', '十', '百', '千', '万', '十万', '百万', '千万'];
  var unit = [];
  if (digit > 3) {
    var multiple = Math.floor(digit / 8);
    if (multiple >= 1) {
      var tmp = Math.round(integer / Math.pow(10, 8 * multiple));
      unit.push(addWan(tmp, number, 8 * multiple, decimalDigit));
      for (var i = 0; i < multiple; i++) {
        unit.push('亿');
      }
      return unit.join('');
    } else {
      return addWan(integer, number, 0, decimalDigit);
    }
  } else {
    return number;
  }
}

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export function debounce(func, wait, immediate) {
  let timer;
  return function() {
    let context = this,
      args = arguments;

    if (timer) clearTimeout(timer);
    if (immediate) {
      let callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timer = setTimeout(() => {
        func.apply;
      }, wait);
    }
  };
}

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 1 表时间戳版，2 表定时器版
 */
export function throttle(func, wait, type) {
  if (type === 1) {
    let previous = 0;
  } else if (type === 2) {
    let timeout;
  }
  return function() {
    let context = this;
    let args = arguments;
    if (type === 1) {
      let now = Date.now();

      if (now - previous > wait) {
        func.apply(context, args);
        previous = now;
      }
    } else if (type === 2) {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(context, args);
        }, wait);
      }
    }
  };
}
/**
 * 获取第一个表格的可视化高度
 */
export function getDocumentScroll(extraHeight) {
  if (typeof extraHeight == 'undefined') {
    extraHeight = 112;
  }
  window.fullHeight = document.documentElement.clientHeight || document.body.clientHeight;
  let tableHeader = null;
  tableHeader = document.getElementsByClassName('ant-table-header')[0];
  let tableTopHeight = 350;
  if (tableHeader) {
    tableTopHeight = tableHeader.getBoundingClientRect().bottom;
  }
  const defaultHeight = window.screen.height - 400;
  let returnHeight = window.fullHeight - tableTopHeight - extraHeight > 300 ? window.fullHeight - tableTopHeight - extraHeight : defaultHeight || 550;
  return returnHeight;
}
/** 去重对象数组
 * @param {Array} arr 对象数组
 * @return {*}
 * */
export function unique(arr) {
  let set = new Set();
  arr?.forEach(item => {
    set.add(JSON.stringify(item));
  });
  arr = [...set].map(item => {
    return JSON.parse(item);
  });
  return arr;
}

export function clearObj(obj) {
  Object.keys(obj).forEach(key => {
    obj[key] = '';
  });
  return obj;
}
export class InitChart {
  static chartList = new Map();
  constructor(options, container) {
    this.container = container;
    this.options = options;
    this.chart = null;
    return this.init();
  }
  // 初始化
  init() {
    if (this.container) {
      if (InitChart.chartList.has(this.container)) {
        InitChart.chartList.get(this.container).dispose();
      }
      const chart = echarts.init(this.container);
      InitChart.chartList.set(this.container, chart);
      chart.setOption(this.options);
      this.observe();
      this.chart = chart;
      return chart;
    }
  }
  // 监听宽高重绘
  observe() {
    // 创建观察者对象
    const _this = this;
    const ele = document.querySelector('.ant-layout-main');
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        _this.resize();
      });
    });
    observer.observe(ele);
    // observer.disconnect(); //停止观察
  }
  // 重绘实例
  resize() {
    this.chart && !this.chart._disposed && this.chart.resize();
  }
}

// 接口
export const interFace = (target, origin) => {
  const obj = {};
  const keys = Object.keys(target);
  keys.forEach(key => {
    obj[key] = origin[key];
  });
  return obj;
};

/** 遍历取值
 * @param {Array} columnOptions 遍历数据源
 * @param {String} columnValue 比较的数据
 * @param {String} key 比较的字段
 * @param {String} text 返回的字段
 * @return {*}
 */
export const columnMap = (columnOptions, columnValue, key = 'value', text = 'text') => {
  if (columnValue) {
    const returnOpt = columnOptions.filter(item => {
      return item[key] == columnValue;
    })[0];
    return returnOpt && returnOpt[text];
  }
};
// 发布订阅模式
export class EventEmitter {
  constructor() {
    this.listeners = {};
  }

  on(type, callback) {
    this.listeners[type] = this.listeners[type] || [];
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      this.listeners[type].forEach(callback => {
        callback(...args);
      });
    }
  }

  off(type, callback) {
    if (this.listeners[type]) {
      const targetIndex = this.listeners[type].findIndex(item => item === callback);
      if (targetIndex !== -1) {
        this.listeners[type].splice(targetIndex, 1);
      }
      if (this.listeners[type].length === 0) {
        delete this.listeners[type];
      }
    }
  }

  offAll(type) {
    if (this.listeners[type]) {
      delete this.listeners[type];
    }
  }
}
