/*
 * @Author: chenxing
 * @Date: 2021-03-22 19:01:57
 * @LastEditors: Yran
 * @LastEditTime: 2021-05-31 15:04:27
 */
import {
    ConfigProvider,
    Layout,
    Input,
    InputNumber,
    Button,
    Switch,
    Radio,
    Checkbox,
    Select,
    Card,
    Form,
    Row,
    Col,
    Modal,
    Table,
    Tabs,
    Icon,
    Badge,
    Popover,
    Dropdown,
    List,
    Avatar,
    Breadcrumb,
    Steps,
    Spin,
    Menu,
    Drawer,
    Tooltip,
    Alert,
    Tag,
    Divider,
    DatePicker,
    TimePicker,
    Upload,
    Progress,
    Skeleton,
    Popconfirm,
    PageHeader,
    Result,
    Statistic,
    Descriptions,
    message,
    notification,
    Empty,
    Tree,
    TreeSelect,
    Cascader,
    Space,
    Pagination,
    Collapse,
    Carousel,
    Calendar,
    AutoComplete,
    Transfer,
    Timeline
} from 'ant-design-vue';

import {
    ApartmentOutlined,
    DashboardOutlined,
    TeamOutlined,
    BarChartOutlined,
    FileTextOutlined,
    FundOutlined,
    UserOutlined,
    FieldTimeOutlined
} from '@ant-design/icons-vue';

export default {
    install: (app, options) => {
        // 引入icon
        let antIcons = {
            ApartmentOutlined,
            DashboardOutlined,
            TeamOutlined,
            BarChartOutlined,
            FileTextOutlined,
            FundOutlined,
            UserOutlined,
            FieldTimeOutlined
        };

        Object.keys(antIcons).forEach(key => {
            app.component(key, antIcons[key]);
        });
        app.config.globalProperties.$antIcons = antIcons;
        app.config.globalProperties.$message = message;

        app.use(ConfigProvider);
        app.use(Layout);
        app.use(Input);
        app.use(InputNumber);
        app.use(Button);
        app.use(Switch);
        app.use(Radio);
        app.use(Checkbox);
        app.use(Select);
        app.use(Card);
        app.use(Form);
        app.use(Row);
        app.use(Col);
        app.use(Modal);
        app.use(Table);
        app.use(Tabs);
        app.use(Icon);
        app.use(Badge);
        app.use(Popover);
        app.use(Dropdown);
        app.use(List);
        app.use(Avatar);
        app.use(Breadcrumb);
        app.use(Steps);
        app.use(Spin);
        app.use(Menu);
        app.use(Drawer);
        app.use(Tooltip);
        app.use(Alert);
        app.use(Tag);
        app.use(Divider);
        app.use(DatePicker);
        app.use(TimePicker);
        app.use(Upload);
        app.use(Progress);
        app.use(Skeleton);
        app.use(Popconfirm);
        app.use(PageHeader);
        app.use(Result);
        app.use(Statistic);
        app.use(Descriptions);
        app.use(Empty);
        app.use(Tree);
        app.use(TreeSelect);
        app.use(Cascader);
        app.use(Space);
        app.use(Pagination);
        app.use(Carousel);
        app.use(Calendar);
        app.use(AutoComplete);
        app.use(Transfer);
        app.use(Collapse);
        app.use(Timeline);
    }
};