/*
 * @Author: chenxing
 * @Date: 2021-03-12 17:27:42
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-16 18:01:13
 */
/*eslint no-irregular-whitespace: ["error", { "skipComments": true }]*/
/*eslint-disabled*/
import router from '@/router';
import menus, { routesList as menuList } from '@/router/menus.js';

const routesFiles = require.context('@/router/routes', false, /\.js$/);

let routes = [];
routesFiles.keys().forEach(file => {
  routes = routes.concat(routesFiles(file).default);
});

function hasPermission(menuList, route) {
  let result = false;
  for (const menu of menuList) {
    if (route.path == menu.url) {
      route.meta.showMask = menu.watermark;
      result = true;
      break;
    }
  }
  return result;
}

function filterMenus(routes) {
  return routes.filter(r => {
    if (r.isShow) {
      if (r.list) {
        r.list = filterMenus(r.list);
      }
      return true;
    }
  });
}

function handleMenus(menus, menusList) {
  // 处理列表里的每一项，进行本地查找，并将本地属性赋予列表项
  let arr = menusList.map(menu => {
    if (menu?.list && menu.list.length > 0) {
      menu.list = handleMenus(menus, menu.list);
    }
    menu.children = menu.list;
    let mtemp = menus.find(m => m.path === menu.url);
    return mtemp ? { ...menu, ...mtemp } : null;
  });
  return arr.filter(item => {
    return item;
  });
}

const state = {
  routes: routes ?? [],
  menus: menus,
  menuList: menuList,
  menuRoutes: [],
  hasRoutes: false,
};
const mutations = {
  // 设置渲染的菜单路由
  SET_MENUROUTES(state, menuRoutes) {
    state.menuRoutes = menuRoutes;
    state.hasRoutes = true;
  },
  CLEAR_ROUTES(state) {
    state.menuRoutes = [];
    state.hasRoutes = false;
  },
};
const actions = {
  // 计算路由信息
  generateRoutes({ commit, state, rootState }) {
    return new Promise(resolve => {
      // let menuRoutes = [];
      // if (rootState.oauth.userInfo.jobs === 'ADMIN') {
      //     menuRoutes = state.menus;
      //     menuRoutes.forEach(menu => {
      //         menu.meta.showMask = true;
      //     });
      // }
      // else
      //     menuRoutes = filterMenus(state.menus, rootState.oauth.userInfo.menuList);

      let handleResult = handleMenus(state.menuList, rootState.oauth.userInfo.menuList);
      let tempRoute = [...handleResult, ...state.routes];

      tempRoute.forEach(r => {
        router.addRoute(r);
      });
      commit('SET_MENUROUTES', filterMenus(handleResult));
      resolve();
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
