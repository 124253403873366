/*
 * @Author: chenxing
 * @Date: 2021-03-04 17:11:04
 * @LastEditors: chenxing
 * @LastEditTime: 2022-01-18 16:27:22
 */
import axios from '@/utils/axios';

const apiUrl = {
    login: '/sys/login',
    thirdPartyLogin: '/sys/third/login',
    logout: '/sys/logout',
    updatePassword: '/sys/user/password',

    userListByJob: '/sys/user/getListByJob',
    userListByJobAndNotDeptId: '/sys/user/getListByJobAndNotDeptId',
    userInfo: '/sys/user/getInfo',
};

export function login(param) {
    return axios.post(apiUrl.login, param);
}

export function thirdPartyLogin(param) {
    return axios.post(apiUrl.thirdPartyLogin, param);
}

export function logout() {
    return axios.post(apiUrl.logout);
}

export function updatePassword(param) {
    return axios.post(apiUrl.updatePassword, param);
}

export function getUserListDataByJob(param) {
    return axios.post(apiUrl.userListByJob, param);
}
export function getListByJobAndNotDeptId(param) {
    return axios.post(apiUrl.userListByJobAndNotDeptId, param);
}

export function getUserInfo() {
    return axios.get(apiUrl.userInfo);
};
