/*
 * @Author: chenxing
 * @Date: 2021-03-15 23:53:57
 * @LastEditors: chenxing
 * @LastEditTime: 2021-06-08 19:22:52
 */
const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {},
        component: () => import('@/views/base/login.vue')
    },
    {
        path: '/exception',
        name: 'exception',
        meta: {},
        redirect: '/exception/404',
        component: () => import('@/components/Layout'),
        children: [{
            path: '/exception/403',
            name: 'exception_403',
            meta: {},
            component: () => import('@/views/exception/403.vue')
        },
        {
            path: '/exception/404',
            name: 'exception_404',
            meta: {},
            component: () => import('@/views/exception/404.vue')
        },
        {
            path: '/exception/500',
            name: 'exception_500',
            meta: {},
            component: () => import('@/views/exception/500.vue')
        }]
    },

];

export default routes;