/*
 * @Author: chenxing
 * @Date: 2021-03-04 14:21:57
 * @LastEditors: chenxing
 * @LastEditTime: 2022-01-11 11:43:14
 */

/*eslint-disabled no-unused-vars*/
/*eslint no-irregular-whitespace: ["error", { "skipComments": true }]*/

import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import NProgress from 'nprogress';
import '@/components/NProgress/nprogress.less';
import storage from 'store';
import axios from 'axios';

NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

// eslint-disable-next-line no-undef
const commonRoutesFiles = require.context('./routes', false, /common.js$/);

// 不需要登录的路由
const notNeedLoginList = [];
let routes = [];
commonRoutesFiles.keys().forEach(file => {
  routes = routes.concat(commonRoutesFiles(file).default);
});

routes.forEach(route => {
  notNeedLoginList.push(route.path);
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  if (localStorage.newVersion) {
    localStorage.frontendVersion = localStorage.newVersion;
    localStorage.removeItem('newVersion');
    location.href = to.path;
    return false;
  }

  if (storage.get('login')) {
    if (!store.state.oauth.userInfo.userId) {
      // 重新获取当前用户信息
      storage.remove('login');
      store.dispatch('oauth/GetUserInfo').then(() => {
        next({
          ...to,
          replace: true
        });
      }, () => {
        next({
          path: '/exception/500',
          replace: true
        });
      });
    } else {
      if (to.matched.length == 0) {
        next({
          path: '/exception/403',
          replace: true
        });
      }
      // 如果有对应的路由，直接进入，否则跳转回主页
      if (to.matched.length > 0 && to.path != '/login') {
        next();
      } else {
        next({
          path: '/',
          replace: true
        });
      }
    }

  } else {
    // 白名单不需要登录
    if (notNeedLoginList.includes(to.path)) {
      next();
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
});

router.afterEach((to, from) => {
  NProgress.done(); // finish progress bar

  if (from.path !== '/') {
    // 计算跟上一次的时间差，存下当前时间戳于storage，并上报数据
    let stayTime = new Date().getTime() - localStorage.getItem('stayTime');
    axios.post('/api/v1/log', { path: window.location.origin + from.path, stayTime });
    localStorage.setItem('stayTime', new Date().getTime());
  }
});

router.onError((error) => {
  router.replace({
    path: '/500'
  });
});

export default router;