/*
 * @Author: chenxing
 * @Date: 2022-01-10 15:06:07
 * @LastEditors: chenxing
 * @LastEditTime: 2022-01-11 11:44:23
 */

import axios from 'axios';

// 读取storage中的上次事件戳并计算，上报此次数据
function reportStayTime() {
    let lastTime = localStorage.getItem('stayTime');
    if (!lastTime) {
        return;
    }
    
    let stayTime = new Date().getTime() - localStorage.getItem('stayTime');
    axios.post('/api/v1/log', { path: window.location.href, stayTime });
}

(() => {
    window.onpageshow = () => {
        localStorage.setItem('stayTime', new Date().getTime());
    };

    // window.onpagehide = () => {
        // 点击一个链接，刷新页面，提交表单，关闭浏览器等时候触发
        // let stayTime = new Date().getTime() - localStorage.getItem('stayTime');
        // let record = localStorage.getItem('data');
        // let data = record && JSON.parse(record) || [];
        // localStorage.setItem('data', JSON.stringify([...data, { path: window.location.href, stayTime }]));
        // reportStayTime();
        // localStorage.removeItem('stayTime');

    // };

    document.addEventListener('visibilitychange', function (event) {
        if (document.hidden) {
            reportStayTime();
        } else {
            localStorage.setItem('stayTime', new Date().getTime());
        }
    });
})();