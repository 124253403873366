/*
 * @Author: chenxing
 * @Date: 2021-03-05 16:34:36
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-18 18:28:46
 */
/*eslint no-irregular-whitespace: ["error", { "skipComments": true }]*/
import storage from 'store';
import { login, logout, getUserInfo, thirdPartyLogin } from '@/api/base/user';

// 储存功能点配置
function processFuncPoint(menuList) {
  return menuList.map(menu => {
    if (menu && menu.list && menu.list.length > 0) {
      return processFuncPoint(menu.list);
    }
    if (menu && menu.funcPointList && menu.funcPointList.length > 0) {
      return menu.funcPointList.map(f => {
        return f.paramKey;
      });
    }
  });
}

const state = {
  userInfo: {},
};
const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};
const actions = {
  Login({ dispatch }, param) {
    return new Promise((resolve, reject) => {
      login(param)
        .then(data => {
          if (data.code === 0) {
            // 获取用户信息
            dispatch('GetUserInfo').then(
              () => {
                resolve();
              },
              error => {
                reject(error);
              }
            );
          } else {
            reject(data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  ThirdPartyLogin({ dispatch }, token, channel) {
    return new Promise((resolve, reject) => {
      let param = { token: token };
      thirdPartyLogin(param)
        .then(data => {
          if (data.code === 0) {
            // 获取用户信息
            dispatch('GetUserInfo').then(
              () => {
                resolve();
              },
              error => {
                reject(error);
              }
            );
          } else {
            reject(data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  Logout({ commit }) {
    return new Promise(resolve => {
      logout().then(() => {
        commit('SET_USERINFO', {});
        commit('route/CLEAR_ROUTES', {}, { root: true });
        storage.remove('login');
        resolve();
      });
    });
  },
  GetUserInfo({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(data => {
        if (data.code != 0) {
          commit('SET_USERINFO', {});
          storage.remove('login');
          reject(data.code);
        } else {
          commit('SET_USERINFO', data.userInfo);
          dispatch('route/generateRoutes', {}, { root: true });
          storage.set('login', true);
          localStorage.setItem('funcPoint', processFuncPoint(data.userInfo.menuList));
          resolve();
        }
      });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
